import { useState } from "react";
import { Link, useLocation  } from "react-router-dom";
import { Row, Col, Drawer } from "antd";
import "antd/dist/antd.css";
import "./index.css";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
  GlowImg,
  Container,
} from "./styles";

function Header(){
  const [visible, setVisibility] = useState(false);

  
 /* const { active, account, library, connector, activate, deactivate } = useWeb3React()
  <Button onClick={connect} >Connect to MetaMask</Button>
      {active ? <p>Connected with {account}</p> : <p>no funca la wea</p>}
      <Button onClick={disconnect} >Disconnect</Button>
  */
  useLocation()
  var Home = true;
  var Token = false;
  if((window.location.href.indexOf("/myMaidens") > -1) || (window.location.href.indexOf("/mint") > -1) || (window.location.href.indexOf("/verify") > -1))
  {
    Home = false
  }
  

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(false);
    window.scrollTo({top: 0});
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
      
    };
    return (
      <>
          

          { Home ? 
        <><CustomNavLinkSmall onClick={() => scrollTo("about")}>
        <Span style={{color:"#fff"}}>About</Span>
        </CustomNavLinkSmall>
        {/*<CustomNavLinkSmall onClick={() => scrollTo("tokenomics")}>
        <Span style={{color:"#fff"}}>Token</Span>
          </CustomNavLinkSmall>*/}
        <CustomNavLinkSmall onClick={() => scrollTo("roadmap")}>
        <Span style={{color:"#fff"}}>Roadmap</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("team")}>
        <Span style={{color:"#fff"}}>Team</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="https://app.uniswap.org/swap?outputCurrency=0xc513a88d49fa7E5dF45295a0aa0190BF01a09f0b&chain=base" target="_blank" rel="noreferrer">
                <Span >Buy</Span>
                </a>
      </CustomNavLinkSmall>
      <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="https://dexscreener.com/base/0xc513a88d49fa7E5dF45295a0aa0190BF01a09f0b" target="_blank" rel="noreferrer">
                <Span >Chart</Span>
                </a>
      </CustomNavLinkSmall>
        <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="https://chat.dystopianmaidens.com" target="_blank" rel="noreferrer">
                <Span >Chat</Span>
                </a>
      </CustomNavLinkSmall>
      <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="https://view.dystopianmaidens.com" target="_blank" rel="noreferrer">
                <Span >Viewer</Span>
                </a>
      </CustomNavLinkSmall>
        
        
        
        </>
        :
        <>
        <a style={{justifyContent:"center"}} href="https://app.uniswap.org/swap?outputCurrency=0xc513a88d49fa7E5dF45295a0aa0190BF01a09f0b&chain=base" target="_blank" rel="noreferrer">
        <Span >Buy</Span>
        </a>
        <a style={{justifyContent:"center"}} href="https://dexscreener.com/base/0xc513a88d49fa7E5dF45295a0aa0190BF01a09f0b" target="_blank" rel="noreferrer">
        <Span >Chart</Span>
        </a>
        <a style={{justifyContent:"center"}} href="https://chat.dystopianmaidens.com" target="_blank" rel="noreferrer">
        <Span >Chat</Span>
        </a>
        <a style={{justifyContent:"center"}} href="https://view.dystopianmaidens.com" target="_blank" rel="noreferrer">
        <Span >Viewer</Span>
        </a>
        </>
        
        }
        {/*
        <CustomNavLinkSmall >
            <Span><Link to="/mint" onClick={onClose}>Mint</Link></Span>
      </CustomNavLinkSmall>
        <CustomNavLinkSmall >
            <Span><Link to="/myMaidens" onClick={onClose}>My Maidens</Link></Span>
        </CustomNavLinkSmall>
        {/**/}
        {/*
        <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="https://www.orbiter.finance/?source=Ethereum&dest=zkSync%20Era" target="_blank" rel="noreferrer">
                <Span >Bridge</Span>
                </a>
                </CustomNavLinkSmall>
        <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="https://mintsquare.io/collection/zksync/0x7e2559c670d510891cb636e7eb860ae1597f7858" target="_blank" rel="noreferrer">
                <Span >MintSquare</Span>
                </a>
                </CustomNavLinkSmall>
    */}
        {/*
        
      */}


      </>
    );
  };

  return (

    
    <HeaderSection style={{}} id="header">
      <Container>
        <Row justify="space-between">
          <Col span={4} >
            <LogoContainer to="/" aria-label="homepage" onClick={onClose} >
              <GlowImg src="logoalt.png" alt="logoalt.png" style={{display: "block",maxWidth:"171px",maxHeight:"72px",width:"auto",height:"auto"}} /> 
            </LogoContainer>
          </Col>
          
          
          
            
            <NotHidden>
            <Col span={24} style={{textAlign:"right"}}>
              <MenuItem />
                <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="https://opensea.io/collection/dystopian-ai-maidens-e741" target="_blank" rel="noreferrer">
                  <img src="/img/ops.svg" alt="ops"  style={{width:"32px",height:"32px"}}/>
                </a>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="https://t.me/DystopianMaidensEntry" target="_blank" rel="noreferrer">
                  <img src="/img/tg.svg" alt="tg.svg"  style={{width:"32px",height:"32px"}}/>
                </a>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="https://twitter.com/DystopianMaiden" target="_blank" rel="noreferrer">
                  <img src="/img/twt.svg" alt="twt.svg"  style={{width:"32px",height:"32px"}}/>
                </a>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall >
                <a style={{justifyContent:"center"}} href="https://discord.gg/PnU3DDVWRy" target="_blank" rel="noreferrer">     
                      <img src="/img/dsc.svg" alt="dsc.svg"  style={{width:"32px",height:"32px"}}/>
                </a>
              </CustomNavLinkSmall>
              </Col>
              </NotHidden>
              
          
          <Burger onClick={showDrawer}>
          <Col span={12}>
            <Outline />
            </Col>
          </Burger>
          
          
          
        </Row>
        
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
                
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
          <div style={{textAlign:"center", paddingTop:"16px"}}>
                <a style={{justifyContent:"center",paddingRight:"16px"}} href="https://opensea.io/collection/dystopian-ai-maidens-e741" target="_blank" rel="noreferrer">
                  <img src="/img/ops.svg" alt="scatter"  style={{width:"32px",height:"32px"}}/>
                </a>
                <a style={{justifyContent:"center",paddingRight:"16px"}} href="https://t.me/DystopianMaidensEntry" target="_blank" rel="noreferrer">
                  <img src="/img/tg.svg" alt="tg.svg"  style={{width:"32px",height:"32px"}}/>
                </a>
                  <a style={{justifyContent:"center",paddingRight:"16px"}} href="https://twitter.com/DystopianMaiden" target="_blank" rel="noreferrer">
                    <img src="/img/twt.svg" alt="twt.svg"  style={{width:"32px",height:"32px"}}/>
                  </a>
                  <a style={{justifyContent:"center"}} href="https://discord.gg/PnU3DDVWRy" target="_blank" rel="noreferrer">     
                        <img src="/img/dsc.svg" alt="dsc.svg"  style={{width:"32px",height:"32px"}}/>
                  </a>
          </div>
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default Header;
