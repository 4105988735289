import styled from "styled-components";
//background: radial-gradient(circle, rgba(83,64,240,1) 52%, rgba(83,64,240,0.8158613787311799) 100%);
//background-attachment:fixed;
export const BackgroundContainer = styled("section")`



  
  overflow-x: hidden;


`;

export const NotMobile = styled("div")`
  
@media only screen and (max-width: 768px) {
  display:none;
}


`;
