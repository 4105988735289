import { lazy } from "react";
import {
  BackgroundContainer,
  NotMobile,
} from "./styles";


const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
//const TokenomicsBlock = lazy(() => import("../../components/Home/TokenomicsBlock"));
const Video2Block = lazy(() => import ("../../components/Home/Video2Block"));
const AboutBlock = lazy(() => import ("../../components/Home/AboutBlock"));
const TeamBlock = lazy(() => import ("../../components/Home/TeamBlock"));
const MarqueeBlock = lazy(() => import ("../../components/Home/MarqueeBlock"));
//const PunkBlock = lazy(() => import ("../../components/Home/PunkBlock"));
const IntroBlock = lazy(() => import ("../../components/Home/IntroBlock"));
const TokenomicsBlock = lazy(() => import ("../../components/Home/TokenomicsBlock"));
const RoadmapBlock = lazy(() => import ("../../components/Home/RoadmapBlock"));
//const ChartBlock = lazy(() => import ("../../components/Home/ChartBlock"));


const Home = () => {
  return (
    <>
    <BackgroundContainer>
    <div style={{
              backgroundImage: `url(/img/introbg.png)`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              zIndex:0
              
        }}>
            <IntroBlock/>
    </div>

        <Container>
        <AboutBlock/>
        </Container>
        <Container>
        <MarqueeBlock/>
        </Container>
        <NotMobile>
        <div style={{
              backgroundImage: `url(/img/cymbg.png)`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              zIndex:0
              
        }}>
          <Video2Block/>
        </div>
        </NotMobile>
        <Container>
        <TokenomicsBlock/>
        </Container>
        <Container>
          <RoadmapBlock/>
        </Container>

        <Container>
          <TeamBlock/>
        </Container>

        <ScrollToTop />
    </BackgroundContainer>
    </>
  );
};
export default Home;
